<template>
  <v-form>
    <loading :active.sync="loading"></loading>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          "
        >
          จัดการสาระความรู้
        </span>
      </v-col>
      <v-col cols="12">
        <v-row>
          <!-- <v-col cols="12" class="mr-4">
            <v-text-field
                hide-details
              class="mb-4"
              v-model="search"
              dense
outlined
              
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col> -->
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn @click="goToCreate()" color="#2AB3A3" dark
              >เพิ่มสาระความรู้</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <!-- <v-col cols="12">
        <v-card outlined class="pa-6">
          <v-row align="center" justify="center">
            
            <img :src="imgUrl" width="50%" class="pa-6" />
          </v-row>
        </v-card>
      </v-col> -->
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersBrand"
            :items="items"
            :search="search"
            disable-pagination
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:[`item.keyImg`]="{ item }">
              <img :src="item.keyImg" width="100px" class="ma-1" />
            </template>
            <template v-slot:[`item.news_title`]="{ item }">
              <span v-if="item.news_title.length < 50">{{
                item.news_title
              }}</span>
              <span v-else>{{ item.news_title.substr(0, 50) }}...</span>
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <v-chip
                small
                v-if="item.type == 'CONTENT'"
                color="green"
                outlined
                >{{ item.type }}</v-chip
              >
              <v-chip small v-if="item.type == 'VIDEO'" color="blue" outlined>{{
                item.type
              }}</v-chip>
            </template>
            <template v-slot:[`item.description`]="{ item }">
              <span v-if="item.description.length < 50">{{
                item.description
              }}</span>
              <span v-else>{{ item.description.substr(0, 50) }}...</span>
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              <span>{{ convertDate(item.createdAt) }}</span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <v-icon @click="UpdateBanner(item)" class="mx-2"
                  >mdi-pencil</v-icon
                >
                <v-icon @click="DeleteBanner(item)">mdi-delete</v-icon>
              </v-row>
            </template>
            <!-- <template v-slot:body="props" v-if="isWindowWidthGreaterThan768">
              <draggable
                :list="props.items"
                tag="tbody"
                @change="DragItems(props.items)"
              >
                <tr v-for="(item, index) in props.items" :key="index">
                  <td v-if="isWindowWidthGreaterThan768">
                    <v-icon small class="page__grab-icon">
                      mdi-arrow-all
                    </v-icon>
                  </td>
                  <td class="text-center">{{ item.sortindex }}</td>

                  <td class="text-center">
                    <span v-if="item.news_title.length < 50">{{
                      item.news_title
                    }}</span>
                    <span v-else>{{ item.news_title.substr(0, 50) }}...</span>
                  </td>
                  <td class="text-center">
                    <span v-if="item.description.length < 50">{{
                      item.description
                    }}</span>
                    <span v-else>{{ item.description.substr(0, 50) }}...</span>
                  </td>
                  <td class="text-center">
                    <img :src="item.keyImg" width="100px" class="ma-1" />
                  </td>

                  <td class="text-center">
                    <v-row justify="center">
                      <v-icon @click="UpdateBanner(item)" class="mx-2"
                        >mdi-pencil</v-icon
                      >
                      <v-icon @click="DeleteBanner(item)">mdi-delete</v-icon>
                    </v-row>
                  </td>
                </tr>
              </draggable>
            </template> -->
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import moment from "moment";
export default {
  components: {
    draggable,
    Loading,
  },
  data() {
    return {
      headersBrand: [
        // { text: "", value: "", align: "start", width: "50" },
        { text: "ลำดับ", value: "sortindex", align: "start" },
        { text: "เนื้อหา", value: "title", align: "start" },
        { text: "ประเภท", value: "type", align: "start" },
        // { text: "เนื้อหา", value: "description", align: "start" },
        // { text: "รูปภาพ", value: "keyImg", align: "start" },
        // { text: "สินค้า", value: "product", align: "start" },
        // { text: "ชื่อยี่ห้อ", value: "bannerName", align: "start" },
        // { text: "รายละเอียด", value: "description", align: "start" },
        { text: "วันที่สร้าง", value: "createdAt", align: "start" },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      // news_type
      items: [],
      search: "",
      sortindex: 0,
      user: "",
      loading: false,
      imgUrl: "",
    };
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
    this.getAllbanner();
  },
  methods: {
    convertDate(val) {
      return moment(val).format("MM/DD/YYYY hh:mm");
    },
    async DragItems(val) {
      this.loading = true;
      console.log("this.itemsssssss", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/knowledges/updateIndex?news_type=ข่าวประชาสัมพันธ์`,
        val,
        auth
      );
      console.log("afterAPI", response);

      this.items = response.data.data;
      // for (let i in this.items) {
      //   this.items[i].count = parseInt(i) + 1;
      // }
      this.loading = false;
    },
    async getAllbanner() {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/knowledges`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAllbannerpe", response.data.data);
      this.items = response.data.data;
      // this.imgUrl = response.data.data.imgUrl;
      for (let i in this.items) {
        this.items[i].sortindex = parseInt(i) + 1;
      }
      console.log("itemBefore", this.items);
      console.log("itemAfter", this.items);
    },
    UpdateBanner(val) {
      localStorage.setItem("Bannerdata3", Encode.encode(val));
      this.$router.push("EditBanners_3");
    },
    ViewBanner(val) {
      localStorage.setItem("Bannerdata3", Encode.encode(val));
      this.$router.push("ViewBanners_3");
    },
    async DeleteBanner(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/knowledges/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllbanner();
        }
      });
    },
    goToCreate() {
      this.$router.push("createbanners_3");
    },
  },
};
</script>